import { useDeferredPaint } from '@blockworks/platform/hooks';

type ShortRelativeTimeProps = {
    className?: string;
    date: number;
};

const ShortRelativeTime = (props: ShortRelativeTimeProps) => {
    const { date, className } = props;
    const ready = useDeferredPaint();

    let result = '';
    const jsDate = date * 1000;
    const diff = Number(new Date(new Date().toLocaleString('en'))) - jsDate;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;

    switch (true) {
        case diff < minute:
            // eslint-disable-next-line no-case-declarations
            const seconds = Math.round(diff / 1000);
            result = `${seconds}s`;
            break;
        case diff < hour:
            result = `${Math.round(diff / minute)}m`;
            break;
        case diff < day:
            result = `${Math.round(diff / hour)}h`;
            break;
        case diff < month:
            result = `${Math.round(diff / day)}d`;
            break;
        case diff < year:
            result = `${Math.round(diff / month)}mn`;
            break;
        case diff > year:
            result = `${Math.round(diff / year)}yr`;
            break;
        default:
            result = '';
            break;
    }

    if (diff < 0) {
        return null;
    }

    return (
        <time suppressHydrationWarning dateTime={new Date(jsDate).toISOString()} className={className}>
            {ready ? result : null}
        </time>
    );
};

export { ShortRelativeTime };
