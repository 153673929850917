import { useEffect, useState } from 'react';

import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

const useDeferredRender = () => {
    const [ready, setReady] = useState(false);
    useIsomorphicLayoutEffect(() => {
        setReady(true);
    }, [setReady]);

    return ready;
};

const useDeferredPaint = () => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setReady(true);
    }, [setReady]);

    return ready;
};

export { useDeferredPaint, useDeferredRender };
