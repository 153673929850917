import * as React from 'react';

import { slugify } from '@blockworks/platform/utils';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { Link } from '@blockworks/ui/legacy/link';

import ByLine from '@/components/pages/frontpage/components/feature/middle/by-line';
import type { ArticleType } from '@/types/article-type';
import decodeEntities from '@/utils/functions/string/decode-entities';
import useBlurhashPlaceholder from '@/utils/hooks/use-blurhash-placeholder';

type FeaturedProps = {
    article: ArticleType;
};

const FeaturedArticleCard = (props: FeaturedProps) => {
    const { article } = props;
    const { excerpt, slug, primaryCategory, title, authors, imageUrl, publishedAt, blurhash } = article;

    const placeholder = useBlurhashPlaceholder(blurhash);

    return (
        <div className="flex flex-col justify-center items-start self-stretch gap-3">
            <div className="flex flex-col justify-start items-start self-stretch gap-2">
                <div className="flex justify-start items-center relative gap-1">
                    <p>
                        {primaryCategory.toLowerCase() !== 'sponsored' && (
                            <Link
                                href={`/category/${slugify(decodeEntities(primaryCategory))}`}
                                className="text-primary text-xs uppercase"
                            >
                                {decodeEntities(primaryCategory)}
                            </Link>
                        )}
                        {article.categories.includes('Sponsored') && (
                            <Link href={`/category/sponsored`} className="text-blue-500 text-xs uppercase">
                                Sponsored
                            </Link>
                        )}
                    </p>
                </div>
                <div className="gap-2.5 font-headline">
                    <Link
                        href={`/news/${slug}`}
                        className="flex-grow text-2xl md:text-3xl xl:text-4xl leading-snug hover:text-primary"
                    >
                        {decodeEntities(title)}
                    </Link>
                </div>
                <div className="gap-2.5">
                    <p className="text-gray-500">{excerpt}</p>
                </div>
                <ByLine authors={authors} publishedAt={publishedAt} className="mt-2 text-xs" />
                <div className="mt-0.5 w-full">
                    <Link href={`/news/${slug}`} className="cursor-pointer">
                        <ImageWithFallback
                            {...placeholder}
                            src={imageUrl}
                            fallbackSrc="/images/Blockworks_Meta_Logo.png"
                            width={768}
                            height={432}
                            decoding="async"
                            className="w-full h-auto"
                            alt="article-image"
                            quality={60}
                            priority
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FeaturedArticleCard;
