import { LongRelativeTime } from './long-relative-time';
import { ShortRelativeTime } from './short-relative-time';

type RelativeTimeProps = {
    className?: string;
    date: number;
};

const RelativeTime = (props: RelativeTimeProps) => {
    if (typeof Intl.RelativeTimeFormat !== 'function') {
        return <ShortRelativeTime {...props} />;
    }
    return <LongRelativeTime {...props} />;
};

export { RelativeTime };
