import { useDeferredPaint } from '@blockworks/platform/hooks';

type LongRelativeTimeProps = {
    className?: string;
    date: number;
};

// in miliseconds
const units = {
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
} as const;

type Units = keyof typeof units;

const LongRelativeTime = ({ date, className }: LongRelativeTimeProps) => {
    const ready = useDeferredPaint();

    const jsDate = date * 1000;
    const now = new Date().getTime();
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

    const getRelativeTime = (d1: number, d2: number) => {
        const elapsed = d2 - d1;
        let unit: Units = 'second';
        for (const u in units) {
            if (Math.abs(elapsed) > units[u as Units]) {
                unit = u as Units;
                break;
            }
        }
        return rtf.format(Math.round(elapsed / units[unit]), unit);
    };

    return (
        <time suppressHydrationWarning dateTime={new Date(jsDate).toISOString()} className={className}>
            {ready ? getRelativeTime(now, jsDate) : null}
        </time>
    );
};

export { LongRelativeTime };
